import { eAction } from '../enums/action.enum';
import { eResource } from '../enums/resources.enum';
import { eRoles } from '../enums/roles.enum';
import { eUserRoles } from '../enums/user-roles.enum';

interface iPermissions {
  [resource: string]: {
    [action: string]: (eRoles | eUserRoles)[];
  };
}

export const PERMISSIONS: iPermissions = {
  [eResource.SCHEDULE_MY_AGENDA]: {
    [eAction.VIEW]: [eRoles.ADMIN, eUserRoles.SCHEDULER_VIEWER, eUserRoles.SCHEDULER_EDITOR, eUserRoles.SCHEDULER_MANAGER]
  },
  [eResource.SCHEDULE_MINISTRIES]: {
    [eAction.VIEW]: [eRoles.ADMIN, eUserRoles.SCHEDULER_EDITOR, eUserRoles.SCHEDULER_MANAGER],
    [eAction.ADD]: [eRoles.ADMIN, eUserRoles.SCHEDULER_MANAGER],
    [eAction.EDIT]: [eRoles.ADMIN, eUserRoles.SCHEDULER_MANAGER],
    [eAction.REMOVE]: [eRoles.ADMIN, eUserRoles.SCHEDULER_MANAGER]
  },
  [eResource.SCHEDULE_EVENTS]: {
    [eAction.VIEW]: [eRoles.ADMIN, eUserRoles.SCHEDULER_EDITOR, eUserRoles.SCHEDULER_MANAGER],
    [eAction.ADD]: [eRoles.ADMIN, eUserRoles.SCHEDULER_MANAGER],
    [eAction.EDIT]: [eRoles.ADMIN, eUserRoles.SCHEDULER_EDITOR, eUserRoles.SCHEDULER_MANAGER],
    [eAction.REMOVE]: [eRoles.ADMIN, eUserRoles.SCHEDULER_MANAGER]
  },
  [eResource.SCHEDULE_EDITOR]: {
    [eAction.VIEW]: [eRoles.ADMIN, eUserRoles.SCHEDULER_EDITOR, eUserRoles.SCHEDULER_MANAGER],
    [eAction.EDIT]: [eRoles.ADMIN, eUserRoles.SCHEDULER_EDITOR, eUserRoles.SCHEDULER_MANAGER]
  },

  [eResource.KIDS_MY_FAMILY]: {
    [eAction.VIEW]: [eRoles.ADMIN, eUserRoles.KIDS_VIEWER, eUserRoles.KIDS_EDITOR, eUserRoles.KIDS_MANAGER]
  },
  [eResource.KIDS_ROOMS]: {
    [eAction.VIEW]: [eRoles.ADMIN, eUserRoles.KIDS_EDITOR, eUserRoles.KIDS_MANAGER],
    [eAction.ADD]: [eRoles.ADMIN, eUserRoles.KIDS_MANAGER],
    [eAction.EDIT]: [eRoles.ADMIN, eUserRoles.KIDS_MANAGER],
    [eAction.REMOVE]: [eRoles.ADMIN, eUserRoles.KIDS_MANAGER]
  },
  [eResource.KIDS_FAMILIES]: {
    [eAction.VIEW]: [eRoles.ADMIN, eUserRoles.KIDS_MANAGER],
    [eAction.ADD]: [eRoles.ADMIN, eUserRoles.KIDS_MANAGER],
    [eAction.EDIT]: [eRoles.ADMIN, eUserRoles.KIDS_MANAGER],
    [eAction.REMOVE]: [eRoles.ADMIN, eUserRoles.KIDS_MANAGER]
  },
  [eResource.KIDS_SETTINGS]: {
    [eAction.VIEW]: [eRoles.ADMIN, eUserRoles.KIDS_MANAGER],
    [eAction.EDIT]: [eRoles.ADMIN, eUserRoles.KIDS_MANAGER]
  },
  [eResource.KIDS_REPORTS]: {
    [eAction.VIEW]: [eRoles.ADMIN, eUserRoles.KIDS_MANAGER]
  },

  [eResource.CALENDAR]: {
    [eAction.VIEW]: [eRoles.ADMIN, eUserRoles.CALENDAR_VIEWER, eUserRoles.CALENDAR_EDITOR, eUserRoles.CALENDAR_MANAGER],
    [eAction.ADD]: [eRoles.ADMIN, eUserRoles.CALENDAR_EDITOR, eUserRoles.CALENDAR_MANAGER],
    [eAction.EDIT]: [eRoles.ADMIN, eUserRoles.CALENDAR_EDITOR, eUserRoles.CALENDAR_MANAGER],
    [eAction.REMOVE]: [eRoles.ADMIN, eUserRoles.CALENDAR_MANAGER]
  },
  [eResource.CALENDAR_ROOMS]: {
    [eAction.VIEW]: [eRoles.ADMIN, eUserRoles.CALENDAR_MANAGER],
    [eAction.ADD]: [eRoles.ADMIN, eUserRoles.CALENDAR_MANAGER],
    [eAction.EDIT]: [eRoles.ADMIN, eUserRoles.CALENDAR_MANAGER],
    [eAction.REMOVE]: [eRoles.ADMIN, eUserRoles.CALENDAR_MANAGER]
  },

  [eResource.GROUP_MY_GROUPS]: {
    [eAction.VIEW]: [eRoles.ADMIN, eUserRoles.GROUP_MANAGER, eUserRoles.GROUP_COORDINATOR, eUserRoles.GROUP_EDITOR, eUserRoles.GROUP_VIEWER]
  },
  [eResource.GROUP]: {
    [eAction.VIEW]: [eRoles.ADMIN, eUserRoles.GROUP_MANAGER, eUserRoles.GROUP_COORDINATOR, eUserRoles.GROUP_EDITOR],
    [eAction.ADD]: [eRoles.ADMIN, eUserRoles.GROUP_MANAGER],
    [eAction.EDIT]: [eRoles.ADMIN, eUserRoles.GROUP_MANAGER, eUserRoles.GROUP_COORDINATOR, eUserRoles.GROUP_EDITOR],
    [eAction.REMOVE]: [eRoles.ADMIN, eUserRoles.GROUP_MANAGER]
  },
  [eResource.GROUP_NETWORKS]: {
    [eAction.VIEW]: [eRoles.ADMIN, eUserRoles.GROUP_MANAGER],
    [eAction.ADD]: [eRoles.ADMIN, eUserRoles.GROUP_MANAGER],
    [eAction.EDIT]: [eRoles.ADMIN, eUserRoles.GROUP_MANAGER],
    [eAction.REMOVE]: [eRoles.ADMIN, eUserRoles.GROUP_MANAGER]
  },
  [eResource.GROUP_SETTINGS]: {
    [eAction.VIEW]: [eRoles.ADMIN],
    [eAction.EDIT]: [eRoles.ADMIN]
  },

  [eResource.CONNECT]: {
    [eAction.VIEW]: [eRoles.ADMIN]
  },
  [eResource.CONNECT_SETTINGS]: {
    [eAction.VIEW]: [eRoles.ADMIN],
    [eAction.EDIT]: [eRoles.ADMIN]
  },
  [eResource.CONNECT_BANNERS]: {
    [eAction.VIEW]: [eRoles.ADMIN],
    [eAction.ADD]: [eRoles.ADMIN],
    [eAction.EDIT]: [eRoles.ADMIN],
    [eAction.REMOVE]: [eRoles.ADMIN]
  }
};
